<template>
  <b-card :title="title" class="m-2">
    <b-alert show variant="secondary" v-if="!sessionsAvailable && !loading"
      >{{selectedUser ? 'No sessions': 'Session data will be displayed here after your first session.'}}</b-alert
    >

    <div v-if="loading" class="text-center m-5">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>

    <b-card class="mb-2" v-if="sessionsAvailable">
      <div class="mb-2">
        <small class="text-muted font-weight-bold text-uppercase">
          {{ `${ selectedUser ? '' : 'My '}Totals` }}
        </small>
      </div>
      <div class="mb-2 lead text-primary">
        <b-icon icon="clipboard-data"></b-icon>
        {{ sessions.length }} Sessions Completed
      </div>

      <hr />
      <div class="mb-2">
        <small class="text-muted font-weight-bold text-uppercase">
          {{ `${ selectedUser ? '' : 'My '}Top Tags` }}
        </small>
      </div>
      <div>
        <b-badge
          v-for="(tag, index) in topTags.slice(0, 5)"
          :key="index"
          variant="primary"
          class="font-weight-normal p-1 mr-1"
          >{{ tag }}</b-badge
        >
      </div>

      <div
        v-if="!topTags || topTags == '' || topTags.length == 0"
        class="small text-muted"
      >
        Visit a Session Page to add tags.
      </div>
    </b-card>

    <b-card
      no-body
      :header="(selectedUser ? '' : 'My ') + 'Sessions (' + sessions.length + ' Total)'"
      footer-tag="footer"
      v-if="sessionsAvailable"
    >
      <b-list-group flush>
        <b-list-group-item
          :to="{ name: selectedUser ? 'AdminSession' : 'Session', params: { id: session._id.toString() } }"
          v-for="(session, index) in sessionsPage"
          :key="index"
        >
          <b-row>
            <b-col cols="1" style="max-width: 32px">
              <b-icon
                icon="calendar"
                variant="primary"
                font-scale="1.25"
                class="mr-1"
              ></b-icon>
            </b-col>
            <b-col>
              <span class="mb-1" style="display: inline-block">
                {{ session.dateFormatted }}</span
              >
              <span> at </span>
              <span style="display: inline-block">
                {{ session.timeFormatted }}
              </span>
            </b-col>
            <b-col class="d-flex justify-content-end align-items-center" v-if="session.responseCount">
              <b-badge>{{session.responseCount}}</b-badge>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-card>
    <b-pagination
      v-if="sessionsTotal > 0"
      v-model="sessionsCurrentPage"
      :total-rows="sessionsTotal"
      :per-page="sessionsPerPage"
      align="center"
      class="mt-3"
    ></b-pagination>
  </b-card>
</template>

<script>
import API from "@/api";

export default {
  name: "Dashboard",
  data() {
    return {
      title: '',
      selectedUser: null,
      sessionsCurrentPage: 1,
      sessionsPerPage: 10,
      sessions: [],
      topTags: [],
      startMoodAverage: null,
      endMoodAverage: null,
      startThoughtsAverage: 0,
      endThoughtsAverage: 0,
      toggle: true,
      loading: true,
    };
  },
  computed: {
    sessionsAvailable() {
      return this.sessions && this.sessions.length > 0;
    },
    firstLogin() {
      return API.firstLogin;
    },
    sessionsPage() {
      const start = (this.sessionsCurrentPage - 1) * this.sessionsPerPage;
      return this.sessions.slice(start, start + this.sessionsPerPage);
    },
    sessionsTotal() {
      return this.sessions.length;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const BSON = require("bson");
      const realmApp = API.realmApp;
      const userIdParam = this.$route.params.id;

      const mongo = realmApp.services.mongodb("mongodb-atlas");
      const mongoCollection = mongo.db("eunoe").collection("sessions");

      const responses = mongo.db("eunoe").collection("responses");
      const questions = mongo.db("eunoe").collection("questions");
      const questionsData = await questions.find();
      const responsesData = await responses.find();

      const mongoUsersCollection = mongo.db("eunoe").collection("users");
      const userFilter = {
        _id: new BSON.ObjectID(userIdParam),
      };
      this.selectedUser = await mongoUsersCollection.findOne(userFilter);
      this.title = `${this.selectedUser ? (this.selectedUser.name || this.selectedUser.email) + '\'s Summary' : 'My Dashboard'}`;
      const filter = {
        userId: userIdParam ? userIdParam.toString() : realmApp.currentUser.customData._id,
      };

      const results = await mongoCollection.find(filter, {
        sort: { startDateTime: -1 },
      });

      // Process all sessions.
      let allTags = [];
      const startMoodTotals = {};
      const endMoodTotals = {};

      let startThoughtsTotal = 0;
      let endThoughtsTotal = 0;

      results.forEach((result) => {
        let sessionResponsesData = responsesData.filter(item => item.sessionId === result._id.toString());
        sessionResponsesData = sessionResponsesData.map(item => {
          const questionItemIndex = questionsData.findIndex(questionData => {
            return questionData._id.toString() == item.questionId;
          });
          return {...item, question: questionsData[questionItemIndex]};
        });
        sessionResponsesData = sessionResponsesData.filter(item => item.question);
        result.responseCount = sessionResponsesData.length;
        
        // Tags.
        if (result.tags) {
          allTags = allTags.concat(result.tags);
        }

        // Thoughts.

        startThoughtsTotal += result.startThoughts;
        endThoughtsTotal += result.endThoughts;

        // Total startMoods.
        if (result.startMood) {
          const startMoodEntries = Object.entries(result.startMood);
          startMoodEntries.forEach((entry) => {
            const [key, value] = entry;
            if (key in startMoodTotals) {
              startMoodTotals[key] += value;
            } else {
              startMoodTotals[key] = value;
            }
          });
        }

        // Total endMoods.
        if (result.endMood) {
          const endMoodEntries = Object.entries(result.endMood);
          endMoodEntries.forEach((entry) => {
            const [key, value] = entry;
            if (key in endMoodTotals) {
              endMoodTotals[key] += value;
            } else {
              endMoodTotals[key] = value;
            }
          });
        }

        result.dateFormatted = new Date(
          result.startDateTime
        ).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });

        result.timeFormatted = new Date(
          result.startDateTime
        ).toLocaleTimeString("en-US");
      });

      // Process tag totals.
      const tagTotals = [];

      allTags.forEach((tag) => {
        let found = false;

        for (let i = 0; i < tagTotals.length; i++) {
          let tagTotal = tagTotals[i];

          if (tag == tagTotal[0]) {
            found = true;
            tagTotals[i] = [tag, tagTotal[1] + 1];
            break;
          }
        }

        if (!found) {
          tagTotals.push([tag, 1]);
        }
      });

      tagTotals.sort((a, b) => {
        return b[1] - a[1];
      });

      this.topTags = tagTotals.map((x) => x[0]);

      // Average thoughts.
      this.startThoughtsAverage = startThoughtsTotal / results.length;
      this.endThoughtsAverage = endThoughtsTotal / results.length;

      // Average startMood.
      Object.keys(startMoodTotals).forEach((key) => {
        startMoodTotals[key] = startMoodTotals[key] / results.length;
      });

      // Average startMood.
      Object.keys(endMoodTotals).forEach((key) => {
        endMoodTotals[key] = endMoodTotals[key] / results.length;
      });

      this.startMoodAverage = startMoodTotals;
      this.endMoodAverage = endMoodTotals;

      console.log("Sessions set");
      this.sessions = results;

      this.loading = false;
    },
  },
};
</script>;